




























































import Vue from 'vue';
import SCircularLoader from '@stratumfive/ui-baseplate/src/components/SCircularLoader/SCircularLoader.vue';
import SAlert from '@stratumfive/ui-baseplate/src/components/SAlert/SAlert.vue';

import { Submission } from '@/rxdb/modules/submissions.module';
import { Form } from '../shared/types';
import { SharedGlobal } from '../rxdb/modules/shared-globals.module';
import { ConsumptionConfig } from '../rxdb/modules/consumption-config.module';

export default Vue.extend({
  name: 'Report',
  components: {
    SCircularLoader,
    SAlert,
  },
  metaInfo: {
    title: 'Report',
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    version: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    form(): Form {
      return this.$store.state.forms.form;
    },
    formLoading(): boolean {
      return this.$store.state.forms.loadingForm;
    },
    globalsLoading(): boolean {
      return this.$store.state.submissionGlobals.loading;
    },
    loading(): boolean {
      return this.formLoading || this.globalsLoading;
    },
    submissionGlobals(): any[] {
      return this.$store.state.submissionGlobals.submissionGlobals;
    },
    sharedGlobals(): SharedGlobal[] {
      return this.$store.state.sharedGlobals.sharedGlobals;
    },
    consumptionConfig(): ConsumptionConfig[] {
      return this.$store.state.consumptionConfigs.consumptionConfigs;
    },
    vesselGlobals(): any[] {
      return this.$store.state.vesselGlobals.vesselGlobals;
    },
    dataOptions(): any[] {
      return this.$store.state.dataOptions.options;
    },
    latestSubmissions(): Submission[] {
      return this.$store.state.submissions.latestSubmissions;
    },

    /**
     * Some report types are not allowed to be created with duplicate timestamps
     */
    allowDuplicateTimestamps(): boolean {
      if (!this.form) {
        return true;
      }
      // TODO this should become configurable on the form-schema level in the admin
      const formTypesToDisallow = ['position-combined', 'arrival', 'departure', 'stoppage', 'stoppage-resume'];
      return !formTypesToDisallow.includes(this.form.type);
    },

    /**
     * If the current report type isn't allowed duplicate timestamps we need to filter the existing
     * submissions down to just the report types that aren't allowed duplicates and pass that through to the
     * SnapshotForm component
     */
    filteredPreviousSubmissions(): Submission[] {
      return this.latestSubmissions
        .filter(
          (submission) => [
            'position-combined', 'arrival', 'departure', 'stoppage', 'stoppage-resume',
          ].includes(submission.type),
        );
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.getForm();
      },
    },
    version() {
      if (!this.formLoading) {
        this.getForm();
      }
    },
  },
  created() {
    this.$store.dispatch('submissionGlobals/load');
  },
  methods: {
    async getForm() {
      this.error = false;
      try {
        await this.$store.dispatch('forms/get', { id: this.id, version: this.version });
      } catch (e) {
        console.error(e);
        this.error = true;
      }
    },
  },
});
